import React from 'react'
import { Col, Button } from 'react-bootstrap'

export default function Home() {
	const openUrls = () => {
		window.open('/shopping')
	}

	const openQr = () => {
		window.open('/qrcode')
	}

	return (
		<>
			<Col className='buttonAlone p-5'>
				<Button onClick={openUrls} size='lg'>
					Faire mes courses
				</Button>
			</Col>
			<Col className='buttonAlone'>
				<Button onClick={openQr} size='lg'>
					Afficher mon Qr Code
				</Button>
			</Col>
		</>
	)
}
