const Shops = [
	{
		name: 'CBD *DEMO*',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'bde7c0de-68e9-483c-880a-901bb5775865',
		privateKey: 'watVcCiW8p4kbsPp6HU3lNJWz+7XDX0UK4cdOojkf8o=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'pharma *DEMO*',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'c7edd642-c817-4d1a-b61d-6a2bf44ec639',
		privateKey: '4YZarnGU/7zLUC2zhWtSMzhRB6c4eVhSxnDHRDRYlKc=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Chapristea *DEMO*',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'FoodDrinks',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'd8a847a1-b112-46f0-89c9-84eb78d21cdf',
		privateKey: 'n05ke9zJbB0vD0kXJqzm2hij5b4clX0ljHm/XqyARa8=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'The Black Lion *DEMO*',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'FoodDrinks',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '319f8c41-d057-4eab-a3f9-08eb74083c19',
		privateKey: 'aw5Y+AbSKzVk2P2x6bjN3JZiPwlY90ra5bl1vI/Z+V8=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'BHV Marais',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Vetements',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'e96223b2-540b-4d25-8bca-4c58f381ef4b',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Carrefour City',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '838817e1-3a42-444d-89a9-e6ac3f3484dd',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Fnac',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '7769db6b-8b3c-4e4c-a0bd-0d89b6892a80',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Monoprix',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'cfaecc95-c638-43fa-9bd6-98003edf11b6',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'carrefourExpress',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'b583749f-2b0b-4e4a-9990-169958b0c26e',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Realm',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '728330b2-03d7-4993-94ba-381cd7aa3ab4',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Super-U',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '2e32945a-ea41-43de-9f3c-787c30056c57',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	//
	{
		name: 'BHV Marais',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Vetements',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'e96223b2-540b-4d25-8bca-4c58f381ef4b',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Carrefour City',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '838817e1-3a42-444d-89a9-e6ac3f3484dd',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Fnac',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '7769db6b-8b3c-4e4c-a0bd-0d89b6892a80',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Monoprix',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'cfaecc95-c638-43fa-9bd6-98003edf11b6',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'carrefourExpress',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'b583749f-2b0b-4e4a-9990-169958b0c26e',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Realm',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '728330b2-03d7-4993-94ba-381cd7aa3ab4',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Super-U',
		address: 'Grand magasin à Paris',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: '2e32945a-ea41-43de-9f3c-787c30056c57',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	//
	{
		name: 'E.Leclerc ALBI',
		address: 'Z.A.C Fonlabour, 81000 Albi',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface',
		tel: '06 57 86 86 89',
		paymentLabel: 'Merci et à bientôt !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'e0972446-fc05-4f42-9e6d-9e9303c4e738',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: "La Grand' Pizzeria",
		address: '4 Rue Castellane, 31000 Toulouse',
		logo: 'pizzaria-logo.jpg',
		tags: ['Restaurant', 'Supply'],
		identifierProducts: 'Pizzeria',
		tel: '06 56 23 23 76',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		tsid: 'd30efcc1-df36-4a47-a402-915692c23e6c',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
	},
	{
		name: 'Pharmacie de la Vère',
		address: 'Route de Cordes, 81140 Cahuzac-sur-Vère',
		logo: 'pharmacie-logo.jpg',
		tags: ['Sante'],
		identifierProducts: 'Pharmacie',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: 'fc025e34-5df8-4621-b765-0058492d9c47',
	},
	{
		name: 'Bowling Marla-Tyla',
		address: '859 chemin de, Saint-Maury, 81150 Labastide-de-Lévis',
		logo: 'bowling-logo.jpg',
		tags: ['Leisure', 'Sport'],
		identifierProducts: 'Bowling',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: '712af67a-cd48-403d-9db2-abadfce87505',
	},
	{
		name: 'Station Service Intermarché',
		address: '19 Avenue Rhin et Danube, 81600 Gaillac',
		logo: 'intermarche-logo.png',
		tags: ['Essence'],
		identifierProducts: 'Essence',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: '9e794439-d807-491c-8da7-91fa4e0f1e84',
	},
	{
		name: 'Jules',
		address: "Rue des Portes d'Albi, 81000 Albi",
		logo: 'jules-logo.png',
		tags: ['Equipment', 'Clothing'],
		identifierProducts: 'Vetements',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: '63cfbf62-a681-43ea-830a-d39b3aaa92ff',
	},
	{
		name: 'Decathlon Albi',
		address: '1 De Melaudie Zac De La Baute, 81990 Le Sequestre',
		logo: 'decathlon-logo.png',
		tags: ['Sport', 'Clothing', 'Gifts'],
		identifierProducts: 'MagasinSport',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: 'dfe0972a-b3d9-445d-b041-e4069562eaad',
	},
	{
		name: 'Auchan Castres',
		address: '10 rue Albert Calmette , 81100 Castres',
		logo: 'Auchan-Logo.png',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface300',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: 'c1a7698e-159a-4027-82ca-1b95c829c284',
	},
	{
		name: 'Biocoop Albi',
		address: '9 Rue du Puech Petit, 81000 Albi',
		logo: 'logo-biocoop-V2-2.png',
		tags: ['Supply'],
		identifierProducts: 'GrandeSurface300',
		paymentLabel: 'Merci et à bientôt dans Pizzeria !',
		siret: '0495938599368',
		tva: '83984839',
		font: 'TYPO FIGLET',
		privateKey: 'eKBH4FJ/gAxjRYqTrvLBWoeBl/9bGxfMWp0hZ05cM0o=',
		publicKey: 'kW4uXn7S2PTZcrABq6DF9Mrp5ZBRDG4WQjE8wN7I2gg=',
		tsid: '5dd76ea2-0b20-4ec3-a3b2-0b97ab36ad66',
	},
	{
		name: 'Shop quipo music',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'dbede65a-4a61-4670-b6e9-194805ce8980',
		privateKey: 'LaREelwsIZ01vFa9ucwZHpoCE2lU4Z3rQDRu9HFWw4Y=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (1)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'b7664895-41aa-499c-a4d2-12ca4107817e',
		privateKey: 'FSgvfWE1DZMQZEti9aHLaSjcIc8FkAaZdgR68xqGjJw=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (2)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '221b1a82-6bbb-40b8-a522-43d67ced38f4',
		privateKey: 'V5B3RGigPItJEdEu87S4BAi4ZGLESbtJ3xVV5eIKvfY=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (3)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '0277688f-9af8-4488-9903-6264b4dddd54',
		privateKey: 'GknJB0SOdUBTS4bdKORIrE3JZtrmF/XQad4doMTeiNE=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (4)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'bf9a7e8d-48f2-40c9-a1e3-5d182d2b4616',
		privateKey: 'qa/Y9Ay2SM4bSR0ElN6xsBskt38LasjhfCenzMs6608=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (5)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '35ffaa5f-77aa-40bd-a4dd-57c8c459553a',
		privateKey: 'u0PAuUfVJ9yXBtp2h+McW7papEaUVGeRkrXkWzPJ7l0=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (6)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '3533b337-5bb6-4356-91fa-ee317f35dd8c',
		privateKey: 'OKqluWY4025VMJwPFAxKfDsqt5GfuZhGJPUnJrXn33k=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (7)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: 'e22bfbae-ded3-475e-acd8-111d96592e19',
		privateKey: 'g25vh69DNF2+KkIi2GXm2PAwFgy3ta8xGOqplwERh1U=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (8)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '636c2ec5-9dcb-4e66-aac4-4b83af6fc31f',
		privateKey: 'pbFd4Q//2eHT0w2uxPigeZ9sz/uPjHV2JwgB71lpdXk=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (9)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '5749ee21-b1fa-4261-a649-e7d9fa245964',
		privateKey: 'NnokQbYsPyTw9rVWeaLP6abOMUOJku9ntwNYEEdF6Xs=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
	{
		name: 'Shop fid shared (10)',
		address: '3 rue de la plage',
		logo: 'leclerc-logo.jpg',
		tags: ['Supply'],
		identifierProducts: 'Pharmacie',
		tel: '06 XX XX XX XX',
		paymentLabel: `C'est l'histoire de la vie !`,
		siret: '04XXXXXXXXXXXXX',
		tva: '83XXXXXXX',
		font: 'TYPO FIGLET',
		tsid: '9ab83c30-01b3-44b1-8fea-b2f750d2e220',
		privateKey: 'GOIMcpxj30+QX7Lr4SnfCfOkOUDX3ZqpCeRure5FhlQ=',
		publicKey:
			'843ada134e6e51f7d5dec801eb59ab3c68c630027c5b1be6fa0b9605b5a15089',
	},
]

const Products = {
	GrandeSurface: [
		{
			name: 'Tomate',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Poivron',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Tablette de chocolat',
			price: 2.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pâte (500g)',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Poulet',
			price: 4.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pastis',
			price: 18.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Beurre',
			price: 4.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Crème fraîche',
			price: 4.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Fromage',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lait',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Yaourt',
			price: 3.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Frites',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Glaces',
			price: 5.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Nuggets',
			price: 3.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Ail',
			price: 0.3,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Asperges',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Aubergines',
			price: 0.9,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Brocolis',
			price: 0.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Carottes',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Celeri',
			price: 0.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Chou',
			price: 0.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Concombres',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Petits pois',
			price: 0.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Farine',
			price: 1.3,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Levure',
			price: 0.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pain de mie',
			price: 2.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sucre vanillé',
			price: 3.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Croquettes',
			price: 6.1,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Litière',
			price: 8.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Eponges',
			price: 1.9,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mouchoirs',
			price: 2.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Papier alu',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Savon vaisselle',
			price: 4.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pistaches',
			price: 1.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mayonnaise',
			price: 5.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Vinaigre',
			price: 8.6,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sel',
			price: 1.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sucre',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Steak haché',
			price: 9.9,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Coton-tiges',
			price: 0.1,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Démaquillant',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Dentifrice',
			price: 3.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Déodorant',
			price: 4.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lames de rasoir',
			price: 2.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Cire',
			price: 2.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lessives',
			price: 8.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mousse à raser',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Papier toilette',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Couches pour bébé',
			price: 6.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Rasoir',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Shampooing',
			price: 3.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Compotes',
			price: 2.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Crèmes dessert',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Fruit au sirop',
			price: 4.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pâte à tartiner',
			price: 7.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Miel',
			price: 4.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Bonbon',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Biscuits',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Barres chocolatées',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
	],
	Pizzeria: [
		{
			name: 'Menu grande pizza BOLOGNA',
			price: 12.9,
			qte: 1,
			tva: 20,
			discount: ['remise eco: - 2'],
		},
		{
			name: 'Menu grande pizza CALZONE',
			price: 11.2,
			qte: 1,
			tva: 20,
			discount: [],
		},
		{
			name: 'Menu grande pizza LAUTA',
			price: 14.9,
			qte: 1,
			tva: 20,
			discount: [],
		},
		{
			name: 'MAROSO',
			price: 14.8,
			qte: 1,
			tva: 20,
			discount: [],
		},
		{
			name: 'Menu grande pizza MOZZAFIATO',
			price: 12.6,
			qte: 1,
			tva: 20,
			discount: ['remise eco: - 2'],
		},
	],
	Pharmacie: [
		{
			name: 'Doliprane',
			price: 4.0,
			qte: 1,
			tva: 20,
			category: 'Tête',
		},
		{
			name: 'Vitamine',
			price: 6.2,
			qte: 1,
			tva: 20,
			category: 'Corps',
		},
		{
			name: 'Protection solaire',
			price: 4.9,
			qte: 1,
			tva: 20,
			category: 'Corps',
		},
		{
			name: 'Crème beauté',
			price: 7.5,
			qte: 1,
			tva: 20,
			category: 'Tête',
		},
	],
	Bowling: [
		{
			name: 'Partie avec barrière',
			price: 8.9,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Partie sans barrière',
			price: 10.9,
			qte: 1,
			tva: 20,
		},
	],
	Essence: [
		{
			name: 'Gazoil',
			price: 1.3,
			qte: 1,
			tva: 10,
		},
		{
			name: '95',
			price: 1.4,
			qte: 1,
			tva: 10,
		},
		{
			name: 'GPL',
			price: 0.8,
			qte: 1,
			tva: 10,
		},
	],
	Vetements: [
		{
			name: 'Chaussure Nike',
			price: 22.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'T-shirt',
			price: 16.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pyjama',
			price: 8.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'chaussette',
			price: 2.0,
			qte: 1,
			tva: 20,
		},
	],
	MagasinSport: [
		{
			name: 'Chaussure football',
			price: 45.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Batton de randonnée',
			price: 22.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Maillot de bain',
			price: 8.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'T-shirt de sport',
			price: 12.0,
			qte: 1,
			tva: 20,
		},
	],
	FoodDrinks: [
		{
			name: 'Lager 50cl',
			price: 9.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lager 25cl',
			price: 22.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Burger Vege',
			price: 11.0,
			qte: 1,
			tva: 10,
		},
		{
			name: 'Onion rings x6',
			price: 7.5,
			qte: 1,
			tva: 10,
		},
	],
	GrandeSurface300: [
		{
			name: 'Tomate',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Poivron',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Tablette de chocolat',
			price: 2.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pâte (500g)',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Poulet',
			price: 4.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pastis',
			price: 18.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Beurre',
			price: 4.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Crème fraîche',
			price: 4.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Fromage',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lait',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Yaourt',
			price: 3.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Frites',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Glaces',
			price: 5.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Nuggets',
			price: 3.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Ail',
			price: 0.3,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Asperges',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Aubergines',
			price: 0.9,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Brocolis',
			price: 0.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Carottes',
			price: 0.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Celeri',
			price: 0.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Chou',
			price: 0.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Concombres',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Petits pois',
			price: 0.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Farine',
			price: 1.3,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Levure',
			price: 0.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pain de mie',
			price: 2.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sucre vanillé',
			price: 3.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Croquettes',
			price: 6.1,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Litière',
			price: 8.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Eponges',
			price: 1.9,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mouchoirs',
			price: 2.7,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Papier alu',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Savon vaisselle',
			price: 4.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pistaches',
			price: 1.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mayonnaise',
			price: 5.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Vinaigre',
			price: 8.6,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sel',
			price: 1.3,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Sucre',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Steak haché',
			price: 9.9,
			qte: 1,
			tva: 20,
		},

		{
			name: 'Coton-tiges',
			price: 0.1,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Démaquillant',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Dentifrice',
			price: 3.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Déodorant',
			price: 4.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lames de rasoir',
			price: 2.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Cire',
			price: 2.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Lessives',
			price: 8.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Mousse à raser',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Papier toilette',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Couches pour bébé',
			price: 6.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Rasoir',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Shampooing',
			price: 3.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Compotes',
			price: 2.2,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Crèmes dessert',
			price: 2.8,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Fruit au sirop',
			price: 4.4,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Pâte à tartiner',
			price: 7.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Miel',
			price: 4.0,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Bonbon',
			price: 1.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Biscuits',
			price: 2.5,
			qte: 1,
			tva: 20,
		},
		{
			name: 'Barres chocolatées',
			price: 1.2,
			qte: 1,
			tva: 20,
		},
	],
}
const Qte = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const Tva = [20, 10, 8.8, 5.5, 2.1]
const Guarantees = [1, 2, 3, 'notificationM-1', 'notification45M-1']

export { Shops, Products, Qte, Tva, Guarantees }
