const Accounts = [
	{
		id: 'Mika',
		username: 'Mika',
		password: 'OcciDev31',
	},
	{
		id: 'Jack',
		username: 'Jack',
		password: 'OcciDev31',
	},
	{
		id: 'Nico',
		username: 'Nico',
		password: 'OcciDev31',
	},
	{
		id: 'Sophie',
		username: 'Sophie',
		password: 'OcciDev31',
	},
	{
		id: 'Julie',
		username: 'Julie',
		password: 'OcciDev31',
	},
	{
		id: 'Enzo',
		username: 'Enzo',
		password: 'OcciDev31',
	},
	{
		id: 'Gui',
		username: 'Gui',
		password: 'OcciDev31',
	},
	{
		id: 'Niki',
		username: 'Niki',
		password: 'OcciDev31',
	},
	{
		id: 'Aurel',
		username: 'Aurel',
		password: 'OcciDev31',
	},
]

export default Accounts
