import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import Accounts from '../mock/accounts'

const Form = () => {
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie, removeCookie] = useCookies(['isConnected'])
	const [username, setUsername] = useState('')
	const [usernameError, setUsernameError] = useState(false)
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState(false)

	const onPressBtn = () => {
		const date = new Date()
		date.setHours(date.getHours() + 1)

		let canConnect = false

		Accounts.forEach((account) => {
			canConnect =
				(username === account.username && password === account.password) ||
				canConnect
		})

		if (canConnect) {
			setCookie('isConnected', true, {
				path: '/',
				sameSite: true,
				expires: date,
			})
			setCookie('username', username, {
				path: '/',
				sameSite: true,
				expires: date,
			})
			setTimeout(() => {
				setCookie('isConnected', false, {
					path: '/',
					sameSite: true,
				})
			}, 1000 * 60 * 60)
		} else {
			setUsernameError(true)
			setPasswordError(true)
		}
	}

	return (
		<Col md={{ span: 4, offset: 4 }}>
			<div className='mt-5'>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Utilisateur
				</label>
				<input
					type='text'
					className={`form-control ${usernameError && 'is-invalid'}`}
					placeholder='Identifiant'
					value={username}
					onChange={(event) => setUsername(event.target.value)}
					onFocus={() => {
						if (usernameError) {
							setUsernameError(false)
						}
					}}
				/>
				<label htmlFor='exampleFormControlInput1' className='form-label'>
					Mot de passe
				</label>
				<input
					type='password'
					className={`form-control ${passwordError && 'is-invalid'}`}
					placeholder='mot de passe'
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					onFocus={() => {
						if (passwordError) {
							setPasswordError(false)
						}
					}}
				/>
				<button onClick={onPressBtn} className='mt-2 btn btn-primary'>
					Connexion
				</button>
			</div>
		</Col>
	)
}

export default Form
