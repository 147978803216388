import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { io } from 'socket.io-client'
import QRCode from 'qrcode.react'
import { useCookies } from 'react-cookie'
import LZUTF8 from 'lzutf8'

export default function QrCode() {
	const maxQrCodeSize = 2500
	// state
	const [username] = useCookies(['username'])
	const [socket, setSocket] = useState(null)
	const [dataQrCode, setDataQrCode] = useState([])

	// establish socket connection
	useEffect(() => {
		setSocket(io('https://demo.occidev31.com:8234/'))
	}, [])
	// listener socket
	useEffect(() => {
		if (socket !== null) {
			socket.emit('connectRoom', { username: username?.username })
			socket.on('receiveData', (data) => {
				try {
					if (data.isNewQrCode) {
						console.log('data.data', data.data)
						setDataQrCode([data.data])
					} else {
						const ticketData = data.data.split('?')[2]
						const numberOfQrCode = Math.ceil(ticketData.length / maxQrCodeSize)

						if (numberOfQrCode === 1) {
							setDataQrCode([data.data])
						} else {
							const baseUrlTicketLess = 'https://api.occidev31.com/mobileapp?'
							const id = Math.floor(Math.random() * Math.pow(2, 31))

							const returnData = []

							for (let i = 0; i < numberOfQrCode; i++) {
								const headerUrl = {
									id: id,
									nbCurrentQrCode: i + 1,
									nbTotalQrCode: numberOfQrCode,
								}
								const header = LZUTF8.compress(JSON.stringify(headerUrl), {
									outputEncoding: 'Base64',
								})
								const url =
									baseUrlTicketLess +
									header +
									'?' +
									ticketData.substr(maxQrCodeSize * i, maxQrCodeSize)

								console.log('url', url)
								returnData.push(url)
							}
							setDataQrCode(returnData)
						}
					}
				} catch (e) {
					console.log('rt')
				}
			})
		}
	}, [socket])

	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies(['isConnected'])

	useEffect(() => {
		const date = new Date()
		date.setSeconds(date.getSeconds() + 10)

		setTimeout(() => {
			setCookie('isConnected', false, {
				path: '/',
				sameSite: true,
			})
		}, 1000 * 60 * 60)
	}, [])

	// render
	return (
		<Col>
			{dataQrCode.length !== 0 && (
				<>
					{dataQrCode.map((data, index) => (
						<div key={index} className='text-center qrCodeCenter pt-5'>
							<div className='textHeader p-4'>
								{'Qr code numéro ' + (index + 1)}
							</div>
							<QRCode size={300} value={data} level='L' includeMargin={false} />
						</div>
					))}
				</>
			)}
			{dataQrCode.length === 0 && (
				<div className='text-center qrCodeCenter pt-5'>
					<div className='fakeQrcode'>
						<p>QR Code / Scan produits en cours</p>
					</div>
				</div>
			)}
		</Col>
	)
}
