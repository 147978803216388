import React from 'react'
import Logo from '../images/logo.svg'

export default function Header() {
	return (
		<>
			<div className='containerLogo'>
				<img src={Logo} alt='logo' className='logoHeader' />
			</div>
			{window.location.pathname === '/shopping' && (
				<div className='subHeader'>
					<p className='textHeader'>Ecran caisse</p>
				</div>
			)}
			{window.location.pathname === '/qrcode' && (
				<div className='subHeader'>
					<p className='textHeader'>Ecran client</p>
				</div>
			)}
		</>
	)
}
