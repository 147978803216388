import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button, Table, Card, Toast } from 'react-bootstrap'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { eddsa as EdDSA } from 'elliptic'
import { Buffer } from 'buffer'
import moment from 'moment'
import { io } from 'socket.io-client'
import { Shops, Products, Qte, Tva, Guarantees } from '../mock/shop'
import { useCookies } from 'react-cookie'
import zlib from 'react-zlib-js'
import base64url from 'base64url'
import axios from 'axios'
import Config from '../config/config'

export default function Shopping() {
	// const
	const { Select, Check } = Form
	// state
	const [shop, setShop] = useState(Shops[0])
	const [products, setProducts] = useState([])
	const [socket, setSocket] = useState(null)
	const [seeToast, setSeeToast] = useState(false)
	const [haveGuarantee, setHaveGuarantee] = useState(true)
	const [guarantee, setGuarantee] = useState(1)

	const renderQuipoTicket = () => {
		const baseUrlTicketLess = Config.baseUrl
		const id = uuidv4()

		const ec = new EdDSA('ed25519')
		const key = ec.keyFromSecret(Buffer.from(shop.privateKey, 'base64')) // hex string, array or Buffer
		const msgHash = Buffer.from(id)
		const signature = key.sign(msgHash).toHex()

		const keyd = ec.keyFromPublic(key, 'hex')
		console.log(keyd.verify(msgHash, signature))

		const dataSignOnBase64 = Buffer.from(signature, 'hex').toString('base64')

		const ticket = {
			tkid: '123456789101112131415161718192019961025',
			tsid: '9b3642d1-311e-4365-adbe-b419d53ba1c6',
			storeName: 'Quipo',
			storeAddress: 'inconnue',
			idSignature: dataSignOnBase64,
			generateAt: '2023-01-01T12:00:00',
			ticketNumber: 'T-01238489302038349',
			totalTTC: 0.0,
			totalHT: 0.0,
			theme: 'whiteGreen',
			tva: [],
			items: [
				{
					name: "cl d'eau non gaspillée",
					priceTTC: 0,
					priceUTTC: 0,
					priceUHT: 0,
					priceHT: 0,
					quantity: 6,
					discount: [],
					category: "1 ticket Quipo c'est:",
				},
				{
					name: 'g de GES non générés',
					priceTTC: 0,
					priceUTTC: 0,
					priceUHT: 0,
					priceHT: 0,
					quantity: 2,
					discount: [],
					category: "1 ticket Quipo c'est:",
				},
				{
					name: 'g de déchets non générés',
					priceTTC: 0,
					priceUTTC: 0,
					priceUHT: 0,
					priceHT: 0,
					quantity: 4,
					discount: [],
					category: "1 ticket Quipo c'est:",
				},
				{
					name: 'Email envoyé',
					priceTTC: 0,
					priceUTTC: 0,
					priceUHT: 0,
					priceHT: 0,
					quantity: 0,
					discount: [],
					category: "Quipo c'est:",
				},
				{
					name: 'Aucune donnée personnelle stockée',
					priceTTC: 0,
					priceUTTC: 0,
					priceUHT: 0,
					priceHT: 0,
					quantity: 0,
					discount: [],
					category: "Quipo c'est:",
				},
			],
			widgets: [
				{
					value:
						'Une application simple d’utilisation et gratuite pour les utilisateurs',
					type: 'null',
				},
				{
					type: 'null',
					value:
						'1 solution unique pour l’ensemble de vos tickets du quotidien quel que soit le mode de paiement (CB, espèce, chèques)!',
				},
				{
					type: 'null',
					value:
						'Fonctionne même en cas de non réception d’un réseau 3G, 4G ou 5G !',
				},
				{
					type: 'null',
					value:
						'Ne demande aucune donnée personnelle aux utilisateurs de l’application',
				},
			],
			qrcode: [],
			ticketdetails: [
				{ t: 'String', h: 'N° ticket', v: 'T-01238489302038349' },
				{ t: 'String', h: 'caissière', v: 'Joan' },
				{ t: 'String', h: 'Id', v: '1838473' },
				{ t: 'String', h: 'N° transaction', v: '*******98294' },
				{ t: 'String', h: 'CB', v: '*******74284' },
			],
		}

		let returnValue = null

		compressData(JSON.stringify(ticket), (data) => {
			const dataString = base64url.fromBase64(
				Buffer.from(data).toString('base64')
			)

			returnValue = baseUrlTicketLess + encodeURI(dataString)
			console.log('returnValue.length', returnValue.length)
			console.log('returnValue', returnValue)
		})

		return returnValue
	}
	// 2.0
	const renderNbProductsString = () => {
		if (products.length === 1) {
			return '(1 article)'
		}
		return `(${products.length} articles)`
	}
	const returnTotalTtcPrice = () => {
		let ttcPrice = 0

		products.forEach((product) => {
			ttcPrice +=
				product.price * product.qte +
				(product.tva / 100) * product.price * product.qte
		})

		return ttcPrice.toFixed(2)
	}
	const returnTotalHtPrice = () => {
		let htPrice = 0

		products.forEach((product) => {
			htPrice += product.price * product.qte
		})

		return parseFloat(htPrice.toFixed(2))
	}
	const renderTicketsElements = () => {
		const items = []
		const tabTva = {}

		products.forEach((product) => {
			const mtHt = product.price * product.qte
			const mtTva = product.price * product.qte * (product.tva / 100)
			const mtTtc = mtHt + mtTva

			if (tabTva[product.tva] === undefined) {
				tabTva[product.tva] = {
					label: product.tva.toString(),
					ht: mtHt,
					tva: mtTva,
					ttc: mtTtc,
				}
			} else {
				tabTva[product.tva] = {
					...tabTva[product.tva],
					ht: tabTva[product.tva].ht + mtHt,
					tva: tabTva[product.tva].tva + mtTva,
					ttc: tabTva[product.tva].ttc + mtTtc,
				}
			}
			items.push({
				name: product.name,
				priceTTC: mtTtc,
				priceUTTC: product.price + product.price * (product.tva / 100),
				priceUHT: product.price,
				priceHT: mtHt,
				quantity: product.qte,
				discount: product.discount === undefined ? [] : product.discount,
				category: product.category === undefined ? '' : product.category,
			})
		})
		const ticketTva = []

		_.forEach(tabTva, (value) => {
			ticketTva.push(value)
		})

		_.orderBy(ticketTva, ['label'], ['asc'])

		return { tabTva, items, ticketTva }
	}
	const renderTvaLines = () => {
		const { tabTva } = renderTicketsElements()
		let returnTab = []

		_.forEach(tabTva, (value) => {
			returnTab.push(value)
		})

		return _.orderBy(returnTab, ['label'], ['asc'])
	}
	const resetShop = () => {
		setProducts([])
		setHaveGuarantee(false)
		setGuarantee(1)
		setSeeToast(false)
	}
	const selectShop = (event) => {
		resetShop()

		const { value } = event.target
		const indexShop = _.findIndex(Shops, (shop) => shop.name === value)
		setShop(Shops[indexShop])
	}
	const addShopItem = () => {
		const product = Object.assign({}, Products[shop.identifierProducts][0])
		if (shop.identifierProducts === 'GrandeSurface300') {
			const returnProducts = []

			Products[shop.identifierProducts].forEach((product) => {
				returnProducts.push({
					...product,
					name:
						Math.floor(Math.random() * 10) +
						product.name +
						Math.floor(Math.random() * 10),
					qte: Math.floor(Math.random() * 100),
					tva: Tva[Math.floor(Math.random() * 5)],
				})
			})

			setProducts([...products, ...returnProducts])
		} else {
			setProducts([...products, product])
		}
	}
	const needDisplayArticles = () => {
		return shop.identifierProducts !== 'GrandeSurface300'
	}

	// ticket transformation
	const compressData = (data, callBack) => {
		zlib.deflate(
			Buffer.from(data),
			{ level: zlib.Z_BEST_COMPRESSION },
			(errors, dataDecoded) => {
				if (errors) {
					console.log('errr', errors)
				} else {
					callBack(dataDecoded)
				}
			}
		)
	}
	const transformData = () => {
		const baseUrlTicketLess = Config.baseUrl
		const id = uuidv4()

		const { items, ticketTva } = renderTicketsElements()

		let guaranteeValue = null
		const dateMoment = moment()
		if (haveGuarantee) {
			if (guarantee === 'notificationM-1') {
				guaranteeValue = dateMoment.add(3, 'm').format('YYYY-MM-DDThh:mm:ss')
			} else if (guarantee == 'notification45M-1') {
				guaranteeValue = dateMoment
					.add(45, 'd')
					.add(3, 'm')
					.format('YYYY-MM-DDThh:mm:ss')
			} else {
				guaranteeValue = dateMoment
					.add(guarantee, 'y')
					.format('YYYY-MM-DDThh:mm:ss')
			}
		}

		const ec = new EdDSA('ed25519')

		const key = ec.keyFromSecret(Buffer.from(shop.privateKey, 'base64')) // hex string, array or Buffer
		console.log(
			'key =',
			Buffer.from(key.getPublic('binary'), 'base64').toString('base64')
		)

		/*
		{
					type: 'CODETWOLABELS',
					labels: [
						'3 articles',
						'5,60€',
						'EAN13',
						'MzAxMTA0MjMwNjg0',
						'#22E085',
					],
					value: '3 articles coupons de 5,60€',
				},
				{
					type: 'CODEONELABEL',
					labels: [
						'Afficher votre code ticket',
						'QRCode',
						'data_in_base64_to_decode_and_display',
						'#C6B9FF',
					],
					value: 'Coupons de 5,60€',
				},
		 */
		const totalTtc = returnTotalTtcPrice()
		const v = 1001003208000000000
		const generateAt = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
		// 57798b85-86dc-42cb-a066-d73b56f52cd7^2^1001003208000000000^2023-03-13T18:38:56.706747+01:00^3747.86
		console.log('totalTtc', totalTtc)
		const msgHash = Buffer.from(
			`${id}^${items.length}^${v}^${generateAt}^${totalTtc}`
		)
		const signature = key.sign(msgHash).toHex()

		const keyd = ec.keyFromPublic(key, 'hex')
		console.log(keyd.verify(msgHash, signature))

		const dataSignOnBase64 = Buffer.from(signature, 'hex').toString('base64')
		const theme = [
			'beigeKhaki',
			'pinkBlue',
			'greyBlack',
			'mandarineRed',
			'whiteGreen',
		]
		const rand = Math.floor(Math.random() * theme.length)

		const ticket = {
			v: 1001003208000000000,
			tkid: id,
			tsid: shop.tsid,
			storeName: shop.name,
			storeAddress: shop.address,
			idSignature: dataSignOnBase64,
			generateAt: generateAt,
			ticketNumber: 'T-01238489302038349',
			totalTTC: parseFloat(returnTotalTtcPrice()),
			totalHT: returnTotalHtPrice(),
			theme: theme[rand],
			tva: ticketTva,
			items: items,
			widgets: [
				{
					type: 'CODETWOLABELS',
					labels: [
						'3 articles',
						'5,60€',
						'EAN13',
						'MzAxMTA0MjMwNjg0',
						'#22E085',
					],
					value: '3 articles coupons de 5,60€',
				},
				{
					type: 'CODEONELABEL',
					labels: [
						'Afficher votre code ticket',
						'QRCode',
						'data_in_base64_to_decode_and_display',
						'#C6B9FF',
					],
					value: 'Coupons de 5,60€',
				},
				{
					type: 'null',
					value:
						'Les échanges se font sous 15 jours sur présentation: De ce ticket Des étiquettes De l’emballage d’origine',
				},
				{
					type: 'null',
					value:
						'Les échanges se font sous 15 jours sur présentation: De ce ticket Des étiquettes De l’emballage d’origine',
				},
				{ type: 'null', value: 'Ici un message' },
				{
					type: 'null',
					value: 'RAPPEL DE TOUTES les PIZZAS SURGELES FRAICH’up Buitoni !',
				},
				{ type: 'null', value: 'La aussi un message' },
			],
			qrcode: [{ t: 'BarCode', h: 'CODE128', v: '93284384834' }],
			ticketdetails: [
				{ t: 'String', h: 'N° ticket', v: 'T-01238489302038349' },
				{ t: 'String', h: 'caissière', v: 'Joan' },
				{ t: 'String', h: 'Id', v: '1838473' },
				{ t: 'String', h: 'N° transaction', v: '*******98294' },
				{ t: 'String', h: 'CB', v: '*******74284' },
			],
			haveGuarantee: guaranteeValue,
		}

		console.log('ticket', ticket)
		let returnValue = null

		compressData(JSON.stringify(ticket), (data) => {
			const dataString = base64url.fromBase64(
				Buffer.from(data).toString('base64')
			)

			returnValue = baseUrlTicketLess + encodeURI(dataString)
			console.log('returnValue.length', returnValue.length)
			console.log('returnValue', returnValue)

			if (returnValue.length > 2500) {
				saveLargeTickets(ticket, encodeURI(dataString))
			} else {
				socket.emit('sendData', {
					data: { data: returnValue, isNewQrCode: true },
					username: username,
				})
			}
		})

		return returnValue
	}
	const saveLargeTickets = (ticket, ticketCompressed) => {
		const baseUrl = 'https://tl-api-v1.occiback.dev/ticket/'

		const ec = new EdDSA('ed25519')

		const key = ec.keyFromSecret(Buffer.from(shop.privateKey, 'base64')) // hex string, array or Buffer

		const msgHash = Buffer.from(ticketCompressed)
		const signature = key.sign(msgHash).toHex()

		const dataSignOnBase64 = Buffer.from(signature, 'hex').toString('base64')

		const instance = axios.create({
			baseURL: baseUrl,
			timeout: 10000,
			headers: {
				'Content-Type': 'application/json',
			},
		})

		instance
			.post(
				ticket.tkid,
				{
					// action: 'SAVE_TICKET',
					// subject: 'CASHDESK',
					meta: {
						tkid: ticket.tkid,
						datasig: dataSignOnBase64,
						tsid: shop.tsid,
					},
					data: ticketCompressed,
					nonce: 123123123,
				},
				{}
			)
			.then(() => {
				console.log('on a réussi')
				compressData(
					JSON.stringify({
						api: true,
						tkid: ticket.tkid,
						tsid: ticket.tsid,
						totalTTC: ticket.totalTTC,
						storeName: shop.name,
						storeAddress: shop.address,
						generateAt: moment().format('YYYY-MM-DDThh:mm:ss'),
					}),
					(data) => {
						const dataString = base64url.fromBase64(
							Buffer.from(data).toString('base64')
						)

						const baseUrlTicketLess = Config.baseUrl
						const urlRender = baseUrlTicketLess + encodeURI(dataString)

						console.log('urlRender', urlRender)
						socket.emit('sendData', {
							data: { data: urlRender, isNewQrCode: true },
							username: username,
						})
					}
				)
			})
			.catch((e) => console.log('eeeeee du premier', e))
	}

	// socket
	const sendData = () => {
		if (socket !== null) {
			const data = transformData()
			socket.emit('sendData', {
				data: { data: data, isNewQrCode: false },
				username: username,
			})
		}
	}

	// session
	const [, setCookie] = useCookies(['isConnected'])
	const [username] = useCookies(['username'])
	useEffect(() => {
		const date = new Date()
		date.setSeconds(date.getSeconds() + 10)

		setTimeout(() => {
			setCookie('isConnected', false, {
				path: '/',
				sameSite: true,
			})
		}, 1000 * 60 * 60)
	}, [])

	// establish socket connection
	useEffect(() => {
		setSocket(io('https://demo.occidev31.com:8234/'))
	}, [])
	// listener socket
	useEffect(() => {
		if (socket !== null) {
			socket.emit('connectRoom', { username: username?.username })
			socket.on('receiveData', () => {
				setSeeToast(true)
			})
		}
	}, [socket])

	useEffect(() => {
		renderQuipoTicket()
	}, [])

	// render
	const TvaLines = renderTvaLines()
	return (
		<>
			<Col md={{ span: 8, offset: 2 }}>
				<h5>Veuillez choisir votre magasin</h5>
				<Select defaultValue={shop.identifierProducts} onChange={selectShop}>
					{Shops.map((shop) => (
						<option key={shop.name} value={shop.name}>
							{`${shop.name} (${shop.address})`}
						</option>
					))}
				</Select>
			</Col>
			<Col>
				<h5 className='pt-5'>Ma liste de course:</h5>
				<Button onClick={addShopItem} variant='success' className='mb-4'>
					Ajouter une course
				</Button>
				{needDisplayArticles() && (
					<>
						{products.length !== 0 && (
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>#</th>
										<th>Qté</th>
										<th>Désignation</th>
										<th>TVA</th>
										<th>Prix/U</th>
										<th>MT.HT</th>
										<th>Total.HT</th>
									</tr>
								</thead>
								<tbody>
									{products.map((product, index) => (
										<tr key={product.name + index}>
											<td>{index}</td>
											<td>
												<Select
													defaultValue={product.qte}
													onChange={(event) => {
														const { value } = event.target
														const newProducts = [...products]

														newProducts[index].qte = parseFloat(value)
														setProducts(newProducts)
													}}
												>
													{Qte.map((qte) => (
														<option key={qte} value={qte}>
															{qte}
														</option>
													))}
												</Select>
											</td>
											<td>
												<Select
													defaultValue={product.name}
													onChange={(event) => {
														const { value } = event.target
														const newProducts = [...products]
														const indexProducts = _.findIndex(
															Products[shop.identifierProducts],
															(product) => product.name === value
														)

														newProducts[index] =
															Products[shop.identifierProducts][indexProducts]

														setProducts(newProducts)
													}}
												>
													{Products[shop.identifierProducts].map((product) => (
														<option key={product.name} value={product.name}>
															{product.name}
														</option>
													))}
												</Select>
											</td>
											<td>
												<Select
													defaultValue={product.tva}
													onChange={(event) => {
														const { value } = event.target
														const newProducts = [...products]

														newProducts[index].tva = parseFloat(value)
														setProducts(newProducts)
													}}
												>
													{Tva.map((tva) => (
														<option key={tva} value={tva}>
															{tva}
														</option>
													))}
												</Select>
											</td>
											<td>{`${product.price} €`}</td>
											<td>{`${(product.price * product.qte).toFixed(2)} €`}</td>
											<td />
										</tr>
									))}
									<tr>
										<td />
										<td />
										<td />
										<td />
										<td />
										<td />
										<td>{returnTotalHtPrice() + ' €'}</td>
									</tr>
								</tbody>
							</Table>
						)}
					</>
				)}
			</Col>
			{products.length !== 0 && (
				<Row>
					<Col md={{ span: 8 }}>
						<Card>
							<Card.Body>
								<Row>
									<Col>{moment().format('DD/MM/YYYY [à] HH:mm')}</Col>
									<Col md='auto'>{renderNbProductsString()}</Col>
								</Row>
								<Col className='underLine w-100 py-1' />
								<Table className='noBorderTab' size='sm'>
									<thead>
										<tr>
											<th>Qté</th>
											<th>Désignation</th>
											<th>TVA</th>
											<th>P.U</th>
											<th>MT.HT</th>
										</tr>
									</thead>
									<tbody>
										{products.map((product, index) => (
											<tr key={product.name + index}>
												<td>{product.qte}</td>
												<td>{product.name}</td>
												<td>{product.tva}</td>
												<td>{`${product.price} €`}</td>
												<td>{`${(product.price * product.qte).toFixed(
													2
												)} €`}</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Col className='underLine w-100 py-1 mb-2' />
								<Row>
									<Col>
										<h6 className='fw-bold'>Total</h6>
									</Col>
									<Col md='auto'>{returnTotalTtcPrice() + ' €'}</Col>
								</Row>
								<Row>
									<Col>
										<h6 className='fw-bold'>Carte bancaire</h6>
									</Col>
									<Col md='auto'>{returnTotalTtcPrice() + ' €'}</Col>
								</Row>
								<Col className='underLine w-100 py-1 mb-2' />
								<Col className='text-center'>
									<h6 className='fw-bold'>Récapitulatif TVA:</h6>
								</Col>
								<Table className='noBorderTab' size='sm'>
									<thead>
										<tr>
											<th>Code TVA</th>
											<th>MT.HT</th>
											<th>MT.TVA</th>
											<th>MT.TCC</th>
										</tr>
									</thead>
									<tbody>
										{TvaLines.map((value) => (
											<tr key={value.label}>
												<td>{value.label}</td>
												<td>{`${value.ht.toFixed(2)} €`}</td>
												<td>{`${value.tva.toFixed(2)} €`}</td>
												<td>{`${value.ttc.toFixed(2)} €`}</td>
											</tr>
										))}
									</tbody>
								</Table>
								<Col className='underLine w-100 py-1 mb-3' />
								<Col className='text-center'>
									<h6 className='fw-bold'>{`Nombre d'articles: ${products.length}`}</h6>
								</Col>
							</Card.Body>
						</Card>
					</Col>
					<Col md={{ span: 4 }}>
						<Check
							className='mb-2'
							type='checkbox'
							checked={haveGuarantee}
							label="Mon ticket dispose d'une garantie jusqu'au:"
							onChange={() => setHaveGuarantee(!haveGuarantee)}
						/>
						{haveGuarantee && (
							<Select
								className='mb-2'
								defaultValue={guarantee}
								onChange={(event) => {
									const { value } = event.target
									console.log('valll', value, typeof value)

									if (
										value === 'notificationM-1' ||
										value === 'notification45M-1'
									) {
										setGuarantee(value)
									} else {
										setGuarantee(parseInt(value, 10))
									}
								}}
							>
								{Guarantees.map((guarantee) => (
									<option key={guarantee} value={guarantee}>
										{`${guarantee} ${guarantee === 1 ? 'an' : 'ans'}`}
									</option>
								))}
							</Select>
						)}
						<Toast
							className='w-100 mb-2'
							show={seeToast}
							onClose={() => setSeeToast(false)}
						>
							<Toast.Header>
								<strong className='me-auto'>QrCode généré !</strong>
							</Toast.Header>
							<Toast.Body>Vous avez bien généré votre Qrcode ;)</Toast.Body>
						</Toast>
						<Button
							disabled={seeToast}
							onClick={() => sendData()}
							variant='dark'
							className='w-100'
						>
							Générer mon QrCode
						</Button>
					</Col>
				</Row>
			)}
		</>
	)
}
