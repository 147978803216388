import React, { useEffect } from 'react'
import RouterMain from './router'
import { Container } from 'react-bootstrap'
import Header from './header'
import Footer from './footer'
import { BrowserRouter as Router } from 'react-router-dom'

export default function Main() {
	// This effect runs once, after the first render
	useEffect(() => {
		document.title = 'TicketLess'
	}, [])

	return (
		<Router>
			<Header />
			<Container>
				<RouterMain />

				<Footer />
			</Container>
		</Router>
	)
}
