import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
// switch
import Home from './home'
import Shopping from './shopping'
import QrCode from './qrCode'
import Form from './form'

export default function RouterMain() {
	const [cookies] = useCookies(['isConnected'])

	return (
		<Switch>
			<Route exact path='/'>
				{cookies.isConnected === 'true' ? <Home /> : <Redirect to='/form' />}
			</Route>
			<Route exact path='/form'>
				{cookies.isConnected === 'true' ? <Redirect to='/' /> : <Form />}
			</Route>
			<Route path='/shopping'>
				{cookies.isConnected === 'true' ? (
					<Shopping />
				) : (
					<Redirect to='/form' />
				)}
			</Route>
			<Route path='/qrcode'>
				{cookies.isConnected === 'true' ? <QrCode /> : <Redirect to='/form' />}
			</Route>
		</Switch>
	)
}
